import { render, staticRenderFns } from "./Contact.vue?vue&type=template&id=3d5e197b&scoped=true"
var script = {}
import style0 from "./Contact.vue?vue&type=style&index=0&id=3d5e197b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d5e197b",
  null
  
)

export default component.exports