<template>
<div class="contact">
<div class="about-section">
  <h1>Santé et nutrition</h1>
</div>
<div>
  <!-- <img src='http://localhost:8000/media/galerie/WhatsApp_Image_2023-10-14_at_21.52.29.jpeg'> -->
  <img src='https://mkw-rdc.hogi.edu.bi/media/galerie/WhatsApp_Image_2023-10-14_at_21.52.29.jpeg'>
</div>
<div class="rows">
  <div class="column">
    <div class="cards">
      <div class="container">
        <p style="font-size: 16px;">
          MkW avec l’appui de ses partenaires œuvre à améliorer la qualité et la disponibilité des soins de santé primaires. Pour cela, MkW apporte une assistance médicale et nutritionnelle en faveur des personnes défavorisées et marginalisées, prend en charge les soins de santé de victimes (déplacés internes, réfugiés, membres de la communauté vulnérables, sinistrés, etc., forme  le personnel soignant, construit, réhabilité les infrastructures médicales (centres de santé,…) et leur fournissons des équipements médicaux et consommables.   
          </p>
          <p>
            
            En plus de cela, MkW organise des activités de la prévention des pandémies et épidémies: VIH, cholera, ect.
        </p>
      </div>
    </div>
  </div>
</div>
<carousel/>
</div>
</template>
<script>
  import Carousel from "@/components/CourselItem.vue"
  export default{
    components:{Carousel}
  }
</script>
<style scoped>
  img{
  width: 100%;
  height: 500px;
}
/*.column {
  float: left;
  width: 33.3%;
  margin-bottom: 16px;
  padding: 0 8px;
}*/

.cards {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.about-section {
  text-align: center;
  background-color: blue;
  color: white;
}
.container {
  padding: 0 16px;
}

.container::after, .row::after {
  content: "";
  clear: both;
  display: table;
}

.title {
  color: grey;
}

.button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.button:hover {
  background-color: #555;
}

@media screen and (max-width: 650px) {
  .column {
    width: 100%;
    display: block;
  }
}
</style>