<template>
<div class="contact">
<div class="about-section">
  <h1> Renforcement du pouvoir économiques des jeunes et femmes</h1>
</div>
<div>
  <!-- <img src='http://localhost:8000/media/galerie/WhatsApp_Image_2023-10-14_at_21.52.29.jpeg'> -->
  <img src='https://mkw-rdc.hogi.edu.bi/media/galerie/WhatsApp_Image_2023-10-14_at_21.52.29.jpeg'>
</div>
<div class="rows">
  <div class="column">
    <div class="cards">
      <div class="container">
        <p style="font-size: 16px;">
          Nous pensons que la promotion du rôle social et économique des jeunes et femmes passe aussi par le renforcement de leurs capacités et de leur aptitude à contribuer à l’amélioration des conditions de vie de leurs familles et au développement de leur communauté.
        </p>

        <p>

        MkW met en œuvre des Groupes de métiers et des Groupes d’Epargne et Crédits dans les zones d’intervention. Ces groupes sont constitués par des jeunes et femmes défavorisés et marginalisés qui se battent jour et nuit pour gagner leur pain quotidien. MkW soutient la formation et l’apprentissage des compétences techniques des femmes et des jeunes, et l’utilisation du système du microcrédit et des fonds de roulement. Un apport en outils et équipement dans les groupes est aussi assuré. Les groupes de métier développent leurs activités génératrices de revenus dans le secteur de l’artisanat et des petits commerces.      
        </p> 
        <ul>
          <li>Appui à la mise en place et à l’accompagnement des Groupes d’Epargne et Crédit (GEC)</li>
          <li>Accompagnement des groupes de métiers des jeunes et femmes.</li>
        </ul>
        </p>
      </div>
    </div>
  </div>
</div>
<carousel/>
</div>
</template>
<script>
  import Carousel from "@/components/CourselItem.vue"
  export default{
    components:{Carousel}
  }
</script>
<style scoped>
img{
  width: 100%;
  height: 500px;
}
.cards {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.about-section {
  text-align: center;
  background-color: blue;
  color: white;
}
.container {
  padding: 0 16px;
}

.container::after, .row::after {
  content: "";
  clear: both;
  display: table;
}

.title {
  color: grey;
}

.button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.button:hover {
  background-color: #555;
}

@media screen and (max-width: 650px) {
  .column {
    width: 100%;
    display: block;
  }
}
</style>