<template>
<div class="contact">
<div class="about-section">
  <h1>PRESENTATION</h1>
</div>
<div class="carousel">
  <!-- <img src='http://localhost:8000/media/galerie/WhatsApp_Image_2023-10-14_at_21.52.29.jpeg'> -->
  <img src='https://mkw-rdc.hogi.edu.bi/media/logo/WhatsApp_Image_2023-11-19_at_16.20.43_1.jpeg' />
</div>

<div class="rows">
  <div class="column">
    <div class="cards">
      <div class="container" style="margin-top: 10px;">
    <p>
			Mukutano kati ya Watu, MkW en sigle est une organisation sans but lucratif de droit congolais régi à la Loi n°004/2001 du 20 juillet 2001 portant dispositions générales applicables aux associations sans but lucratif et auxétablissements d&#39;utilité publique en RDC.
    </p>
    <p>
    MkW a été créée le 12 janvier 2020 par des jeunes congolais convaincus et conscients que la solidarité, la coopération et le partage influence la culture de la paix, de la non-violence et du développement durable. Son siège social est établi sur l’avenue Mayi ya Moto, Quartier Kavimvira, Ville d’Uvira, province du Sud-Kivu en RDC. La zone d’intervention de MkW couvre toute l’étendue de la RDC, toutefois, actuellement, MkW dispose des bureaux opérationnels à Bukavu, Goma et Kalemie avec intense activités dans les provinces du Nord-Kivu, Sud-Kivu et Tanganyika      
    </p>
    <p>
      
    </p>
      C’est depuis 2020 qu’il intervient dans les domaines de Sécurité alimentaire et financière, de l’environnement et la bonne gestion des ressources naturelles, du genre et protection de l’enfant, de l’éducation à la paix et à la citoyenneté active, de la Santé, eau, hygiène et assainissement ainsi que de l’éducation. Dans toutes ses interventions, MkW met un accent particulier aux personnes démunis, surtout les enfants, jeunes et femmes. MkW adopte l’approche communautaire. Cette approche garantit un niveau élevé de responsabilité et de participation des bénéficiaires et des autres parties prenantes aux activités. Vision
      MkW rêve «Un monde exempté de la pauvreté et discrimination, capable de réaliser les idéaux de l’inclusion, de l’épanouissement, de la solidarité, coopération et partage».
		<p>
			<h2>Vision</h2>
      <p> MkW rêve «Un monde exempté de la pauvreté et discrimination, capable de réaliser les idéaux de l’inclusion, de l’épanouissement, de la solidarité, coopération et partage».  <p>
		</p>
      <h2>Missions</h2>
      <ul>
        <li>Accompagner et soutenir le processus endogènes d’épanouissement des couches sociales faibles, défavorisées et marginalisées</li>
        <li>Promouvoir les droits humains, civils, sociaux, politiques et d’une culture de l’égalité, paix, non-violence, solidarité, coopération, partage, au-delà de toute différence.</li>
      </ul>
      <p>
        Avec ces missions, MkW, pour la mise en œuvre de ses actions, procède par l’identification des besoins sur terrain avec la participation active de ses bénéficiaires. Au cours de cette phase, elle combine l’enquête ménage et le focus groupe pour récolter de données fiables qui, par la suite, font objet de validation dans des ateliers avec les bénéficiaires où les besoins prioritaires sont définis. De ces besoins, des stratégies d’intervention concertées sont alors décidées laissant alors l’équipe technique de MkW d’élaborer des programmes et projets répondant aux besoins réels de ses groupes cibles.
      </p>
		</p>
		<p>
      <h2>Organes statutaires</h2>
      MkW dispose des organes nécessaires pour son bon fonctionnement, qui sont:
      <ul>
        <li>L’Assemblée Généraleest composée de 16 personnes</li>
        <li>Le Conseil d’administration est composé de 6 personnes</li>
        <li>La Présidence est composée de 2 personnes. A côté de la présidence, s’attache une Direction Générale qui est l’organe d’exécution des activités planifiées.</li>
      </ul>
      <p>
        Les membres effectifs cotisent annuellement 5 à 10$ pour contribuer à la vie de l’organisation et assurer son bon fonctionnement.
      </p>
		</p>
    </div>
    </div>
  </div>
</div>
</div>
</template>
<script>
  import Card from '@/components/Cards'
  export default {
    components:{Card}
  }
</script>

<style scoped>
/*.column {
  float: left;
  width: 33.3%;
  margin-bottom: 16px;
  padding: 0 8px;
}*/

/*.cards {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}*/

.about-section {
  text-align: center;
  background-color: blue;
  color: white;
}

.container {
  padding: 0 16px;
}
.carousel{
/*  width: 100%;*/
margin-left: 20px;
margin-right: 20px;
  position: relative;
  overflow: hidden;
  height: 50vh;
}

img {
  position: absolute;
  width:100%;
}

.container::after, .rows::after {
  content: "";
  clear: both;
  display: table;
}

.title {
  color: grey;
}

.button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.button:hover {
  background-color: #555;
}

@media screen and (max-width: 650px) {
  .column {
    width: 100%;
    display: block;
  }
}
</style>