<template>
	<div>
		<Carousel></Carousel>
		<Content/>
		<Card/>
		<Realisation/>
		<aide/>
	</div>
</template>
<script>
	import Carousel from "@/components/CourselItem.vue"
	import Content from "@/components/Content.vue"
	import Card from "@/components/Cards.vue"
	import Realisation from "@/components/Realisation.vue"
	import aide from "@/components/aide.vue"
	export default{
		components:{Carousel, Content, Card, Realisation, aide}
	}
</script>