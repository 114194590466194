<template>
  <div>
    <transition-group name="fade" tag="div">
      <div v-for="i in [currentIndex]" :key="i">
        <img :src="currentImg" />
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: "Slider",
  data() {
    return {
      images: [
        "https://mkw-rdc.hogi.edu.bi/media/galerie/WhatsApp_Image_2023-10-14_at_21.52.29_1.jpeg",
        "https://mkw-rdc.hogi.edu.bi/media/galerie/IMG-20231111-WA0016_wGXBm8H.jpg",
        "https://mkw-rdc.hogi.edu.bi/media/galerie/IMG-20231111-WA0014.jpg",
        "https://mkw-rdc.hogi.edu.bi/media/galerie/IMG-20231111-WA0013.jpg",
        "https://mkw-rdc.hogi.edu.bi/media/galerie/IMG-20231111-WA0015_1.jpg",
      ],
      timer: null,
      currentIndex: 0
    };
  },

  mounted: function() {
    this.startSlide();
  },

  methods: {
    startSlide: function() {
      this.timer = setInterval(this.next, 4000);
    },

    next: function() {
      this.currentIndex += 1;
    },
    prev: function() {
      this.currentIndex -= 1;
    }
  },

  computed: {
    currentImg: function() {
      return this.images[Math.abs(this.currentIndex) % this.images.length];
    }
  }
};
</script>
<style scoped>
.fade-enter-active
.fade-leave-active {
  transition: all 0.9s ease;
  overflow: hidden;
  visibility: visible;
  position: absolute;
  width:100%;
  opacity: 1;
}

.fade-enter
.fade-leave-to {
  visibility: hidden;
  width:100%;
  opacity: 0;
}

img {
  height:40%;
  width:100%
}

.prev .next {
  cursor: pointer;
  position: absolute;
  top: 40%;
  width: auto;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.7s ease;
  border-radius: 0 4px 4px 0;
  text-decoration: none;
  user-select: none;
}

.next {
  right: 0;
}

.prev {
  left: 0;
}

.prev .next:hover {
  background-color: rgba(0,0,0,0.9);
}
</style>

