<template>
<div class="contact">
<div class="about-section">
  <h1> Environnement et gestion des ressources naturelles</h1>
</div>
<div>
  <img src='http://localhost:8000/media/galerie/WhatsApp_Image_2023-10-14_at_21.52.29.jpeg'>
  <!-- <img src='https://mkw-rdc.hogi.edu.bi/media/galerie/217439732_467953454291452_2245710742564897330_n.jpg'> -->
</div>
<div class="rows">
  <div class="column">
    <div class="cards">
      <div class="container">
        <p style="font-size: 16px;">
        MkW fournit des arbres aux communautés et leur apprend à les produire. Il sensibilise la communauté sur la protection de l’environnement, appui aux campagnes annuelles de reboisement et accompagne les pépiniéristes communautaires.
        </p>
        <p>
          Depuis sa création, au moins 150.000 plants de Papayer, Citronnier, Moringa oléifera, Orangers,Avocatier, Calliandra, Cyprès, Grevellea, Mimosa, Cedrela, Acacia, Leucena, Eucalyptus ont été produits et distribués aux bénéficiaires.
        </p>
        <p>
          <p>
          Dans ce domaine MkW concentre ses énergies sur deux volets:
          </p>
          <ul>
            <li>Reboisement</li>
            <li>Animation communautaire sur la protection de l’environnement, la justice climatique et la gestion durable des ressources naturelles.</li>
            
          </ul>
       </p>
      </div>
    </div>
  </div>
</div>
<carousel/>
</div>
</template>
<script>
  import Carousel from "@/components/CourselItem.vue"
  export default{
    components:{Carousel}
  }
</script>
<style scoped>
/*.column {
  float: left;
  width: 33.3%;
  margin-bottom: 16px;
  padding: 0 8px;
}*/

.cards {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.about-section {
  text-align: center;
  background-color: blue;
  color: white;
}
.container {
  padding: 0 16px;
}

.container::after, .row::after {
  content: "";
  clear: both;
  display: table;
}

.title {
  color: grey;
}

.button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.button:hover {
  background-color: #555;
}

@media screen and (max-width: 650px) {
  .column {
    width: 100%;
    display: block;
  }
}
img{
  width: 100%;
  height: 500px;
}
</style>