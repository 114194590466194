<template>
<div class="contact">
<div class="about-section">
  <h1> Eau, hygiène et assainissement</h1>
</div>
<div>
  <img src='http://localhost:8000/media/galerie/WhatsApp_Image_2023-10-14_at_21.52.29.jpeg'>
  <!-- <img src='https://mkw-rdc.hogi.edu.bi/media/galerie/WhatsApp_Image_2023-10-14_at_21.52.29.jpeg'> -->
</div>
<div class="rows">
  <div class="column">
    <div class="cards">
      <div class="container">
      <p style="font-size: 16px;">
        MkW avec ses partenaires met en œuvre des actions visant en renforcer l’hygiène et l’assainissement dans la communauté et lutte contre les maladies hydriques. A ce sens, il réalise des activités de captage et forage d’eau,
      </p>
      Adduction d’eau, promotion de l’hygiène et prévention des maladies, construction et équipement des infrastructures hydraulique ainsi que la distribution des kits de lavage des mains et produits de traitement d’eau.
        </p>
      </div>
    </div>
  </div>
</div>
<carousel/>
</div>
</template>
<script>
  import Carousel from "@/components/CourselItem.vue"
  export default{
    components:{Carousel}
  }
</script>
<style scoped>
img{
  width: 100%;
  height: 500px;
}

.cards {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.about-section {
  text-align: center;
  background-color: blue;
  color: white;
}
.container {
  padding: 0 16px;
}

.container::after, .row::after {
  content: "";
  clear: both;
  display: table;
}

.title {
  color: grey;
}

.button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.button:hover {
  background-color: #555;
}

@media screen and (max-width: 650px) {
  .column {
    width: 100%;
    display: block;
  }
}
</style>