<template>
<div class="contact">
<div class="about-section">
  <h1> Education</h1>
</div>
<div>
  <!-- <img src='http://localhost:8000/media/galerie/WhatsApp_Image_2023-10-14_at_21.46.43.jpeg'> -->
  <img src='https://mkw-rdc.hogi.edu.bi/media/galerie/WhatsApp_Image_2023-10-14_at_21.46.43.jpeg'>
</div>
<div class="rows">
  <div class="column">
    <div class="cards">
      <div class="container">
        <p style="font-size: 16px;">
          MkW avec l’appui de ses partenaires apporte un soutien nécessaire dans le domaine de l’éducation. Il prend en charge la scolarisation des enfants vulnérables grâce aux différents bienfaiteurs, apporte les matériels et fournitures scolaires aux enfants, organise des cours d’alphabétisation aux adultes et assure une formation professionnelle aux enfants, jeunes et femmes marginalisés et défavorisées.
        </p>
        <p>
          Au sein des différentes écoles primaires et secondaires de la RDC, MkW met en œuvre une approche de jardins scolaires pour permettre aux écoles de renforcer leurs didactiques et se procurer de moyens pouvant leur permettre de soutenir certains enfants démunis et les équipements au sein de l’école.
        </p>
        <p>
          MkW forme le personnel enseignant, construit, réhabilite et équipe des infrastructures scolaires et les centres de formation professionnelle.
        </p>
      </div>
    </div>
  </div>
</div>
<carousel/>
</div>
</template>
<script>
  import Carousel from "@/components/CourselItem.vue"
  export default{
    components:{Carousel}
  }
</script>

<style scoped>
/*.column {
  float: left;
  width: 33.3%;
  margin-bottom: 16px;
  padding: 0 8px;
}*/

.cards {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.about-section {
  text-align: center;
  background-color: blue;
  color: white;
}
.container {
  padding: 0 16px;
}

.container::after, .row::after {
  content: "";
  clear: both;
  display: table;
}

.title {
  color: grey;
}

.button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.button:hover {
  background-color: #555;
}

@media screen and (max-width: 650px) {
  .column  {
    width: 100%;
    display: block;
  }
}
img{
  width: 100%;
}
</style>