<template>

	<div class="nav-items">
			<div>
		<Hotbar/>
	</div>
	 <nav class="navbar navbar-expand-sm   navbar-light bg-light primary-navigation"  role="navigation" >
	        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
	          <span class="navbar-toggler-icon"></span>
	        </button>

	        <div>
	        	<router-link to='/'>
          		<!-- <img src=" http://127.0.0.1:8000/media/logo/MkW.jpg"> -->
          		<img src="https://mkw-rdc.hogi.edu.bi/media/logo/MkW.jpg">	        	
	        		
	        	</router-link>
	        </div>       	
	        <div class="collapse navbar-collapse" id="navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
	          <ul class="navbar-nav mr-auto mt-2 mt-lg-0" data-toggle="collapse" data-target="#navbarTogglerDemo03" >
	            <li class="nav-item">
	              <router-link class="nav-link" to="/">à propos <span class="sr-only">(current)</span></router-link>
	            </li>
	            <li class="nav-item dropdown dmenu">
	            <a class="nav-link dropdown-toggle"  id="navbardrop" data-toggle="dropdown">
	              Ce que nous sommes
	            </a>
	            <div class="dropdown-menu sm-menu">
	              <router-link class="dropdown-item" to="/presentation">Présentation</router-link>
	              <router-link class="dropdown-item" to="/equipe">Equipe Technique</router-link>
	            </div>
	          </li>
	           <li class="nav-item dropdown dmenu">
	            <a class="nav-link dropdown-toggle"  id="navbardrop" data-toggle="dropdown">
	              Domaines d'intervention
	            </a>
	            <div class="dropdown-menu sm-menu dropdown">
	              <router-link class="dropdown-item" to="/agriculture">Agriculture et securité alimentaire</router-link>
	              <router-link class="dropdown-item" to="/droits-humains">Droits humains et citoyenneté active</router-link>
	              <router-link class="dropdown-item" to="/education">Education</router-link>
	              <router-link class="dropdown-item" to="/environnement">Environnement et bonne gestion des ressources naturelles</router-link>
	              <router-link class="dropdown-item" to="/renforcement">Renforcement du pouvoir economique des jeunes et des femmes</router-link>
	              <router-link class="dropdown-item" to="/eau-hygiene">Eau, Hygiène et assainissement</router-link>
	              <router-link class="dropdown-item" to="/sante-nutrition">Santé et nutrition</router-link>
	              <router-link class="dropdown-item" to="/inclusion">Inclusion des personnes à besoin specifique</router-link>
	            </div>
	          </li>
	          <li class="nav-item dropdown dmenu">
	            <a class="nav-link dropdown-toggle"  id="navbardrop" data-toggle="dropdown">
	              Nos projets
	            </a>
	            <div class="dropdown-menu sm-menu">
	              <router-link class="dropdown-item" to="/projet_passe">Projets passés</router-link>
	              <router-link class="dropdown-item" to="/projet_en_cours">Projets en cours</router-link>
	            </div>
	          </li>
	          <li class="nav-item">
	            <router-link class="nav-link" to="/rapports">Nos rapports</router-link>
	          </li>
	          <li class="nav-item">
	            <router-link class="nav-link" to="/contact">Contacts</router-link>
	          </li>
	          <li class="nav-item">
	          	<!-- <button style="background-color: red"> -->
	 	           <router-link class="nav-link" style="background-color: red; " to="/don/"><span style="font-size:12px;">Faire un don </span></router-link>	          		
	          	<!-- </button> -->
	          </li>
	          </ul>
	        </div>
	      </nav>
	
</div>
</template>
<script>
  import Hotbar from "../components/Hotbar"
	import axios from "axios"
	export default{
		components:{Hotbar},
		data(){
			return {
				logo:this.$store.state.logo,
			}
		},
		mounted() {
		    axios.get(this.$store.state.url + "/logo/")
		        .then(res => {
		          this.logo = res.data
		          console.log(res.data)
		        })
		        .catch(err => {
		            console.error(err); 
		        })
		    },  
	}
</script>
<style scoped>
	ul{
		float: right;	}
	.bg-light {
    background-color: #1cd3c4!important;
    color: white;
		}
		.navbar-light .navbar-nav .nav-link {
		    color: #fff;
		    float: right;
		    justify-content: flex-end;
		}
	ul li a{

	    margin-right: 10px;
	    text-transform: uppercase;
	}
	.nav-link ul li a:hover{
		color: #000;
	}
	.icon {
	  color: white;
	}
	img{
		width: 80px;
		height: 60px;
	}
	.text-white{
		font-weight: bold;
	}
	.left{
		text-align: right;
  color: white;
  padding: 3px;
  margin-right: 10px;
	}
	i{
		font-size: 24px;
		margin: 5px;
	}
	p{
		display: flex;
	}
	img{
		padding-right: 25px;
		margin: 5px;
	}
	.dropdown-item:link {
  background-color: white;
	}
	.dropdown-item:visited {
	  background-color: #f4fe;
	  border-radius: 30px;
	  color: #000;
	}
	.nav-item:visited {
	  background-color: #f2f2;
	}


	.dropdown-item:hover {
	  background-color: #FF7D00!important;
	  border-radius: 30px;
		padding: 5px 5px;
		font-size: 24px;
		color: #000;
;
	}
 
	.nav-item:hover {
		background-color: #FF7D00;
		border-radius: 20px;
		font-size: 24px;
	}
	div.dropdown .dmenu{
		background-color: #f3f3;
	}


nav.primary-navigation li a {
  color: black;
}
nav.primary-navigation li a:hover {
  color: #3ca0e7;
}
nav.primary-navigation li:hover {
  cursor: pointer;
}
nav.primary-navigation ul li ul {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  padding-left: 0;
  left: 0;
  display: none;
  background: white;
}
nav.primary-navigation ul li:hover > ul,
nav.primary-navigation ul li ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
  min-width: 250px;
  text-align: left;
  padding-top: 20px;
  box-shadow: 0px 3px 5px -1px #ccc;
}
nav.primary-navigation ul li ul li {
  clear: both;
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
  border-style: none;
}
nav.primary-navigation ul li ul li a:hover {
  padding-left: 10px;
  border-left: 2px solid #3ca0e7;
  transition: all 0.3s ease;
}

a {
  text-decoration: none;
}
a:hover {
  color: #3CA0E7;
}

ul li ul li a {
  transition: all 0.5s ease;
}
</style>

